@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .font-helvetica {
    font-family: Helvetica;
    font-size: 18px;
    /* text-shadow: 2px 2px 50px black; */
  }
}

@layer utilities {
  .main-bg-clip {
    background-image: url(../public/OrangeBGGradient.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    clip-path: polygon(0 0, 100% 18%, 100% 100%, 0 100%);
  }

  .basic-bg {
    background-image: url(../public/OrangeBGGradient.jpg);
    background-repeat: no-repeat;
    background-size: cover;
  }

  .hiring-bg {
    background-image: url(../public/OrangeBGGradient.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    /* clip-path: polygon(0 0, 100% 18%, 100% 100%, 0 100%); */
  }
  .admin-dashboard-bg {
    background-image: url(../public/OrangeBGGradient.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    /* clip-path: polygon(0 0, 100% 18%, 100% 100%, 0 100%); */
  }

  .job-bg {
    background-image: url(../public/offerBg.jpg);
    background-repeat: no-repeat;
  }

  .formResume-sideBar {
    background-image: url(../public/OrangeBGGradient.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    clip-path: polygon(100% 0, 100% 100%, 10% 100%, 0 30%);
  }

  .gradient_template2 {
    background: linear-gradient(180deg, #e2e8f0, #fff6f6);
  }

 .custom-shape{
  width:20%;
  height:20%;
  background-color: #e2f1e9;
  clip-path: polygon(0 0, 100% 0%, 0 100%, 0 46%)
 }

 .curved-triangle {
  width: 550px;
  height: 550px;
  background: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"%3E%3Cpath fill="%23e2f1e9" d="M0,0 L100,0 Q85,30 45,50 Q10,70 0,100 Z" /%3E%3C/svg%3E') no-repeat;
  background-size: cover;
}

.red-grad{
  background: linear-gradient(to bottom right, #8B0000 10%, #ffffff 50%);
}
.bg-transparent{
background-color: transparent;
}
.lollipop {
  display: flex;
  align-items: center;
}

.circle {
  width: 10px;             /* Diameter of the circle */
  height: 10px;
  background-color: #8B0000; /* Color of the circle */
  border-radius: 50%;       /* Makes it a circle */
  margin-right: 0px;       /* Spacing between the circle and the line */
}

.line {
  width: 200px;             /* Length of the line */
  height: 3px;              /* Thickness of the line */
  background-color: #8B0000; /* Color of the line */
}

.border-orange{
  border : 2px solid #ccc
}

html, body {
  max-width: 100%;
  overflow-x: hidden;
}
.dark-green{
  color: '#25361c'

}

/* Background and sharp corners */
.custom-toast-success {
  background-color: #50BE87 !important; 
  border-radius: 0 !important;  
  color: white !important;  
}

/* White fading progress bar */
.custom-progress-bar {
  background: rgba(255, 255, 255, 0.8) !important; 
}
.custom-toast-success .Toastify__toast-icon svg {
  fill: white !important; 
}
.custom-toast-danger .Toastify__toast-icon svg {
  fill: white !important; 
}

/* Background and sharp corners */
.custom-toast-danger {
  background-color: #FF543E !important; 
  border-radius: 0 !important;         
  color: white !important;          
}

}
